import {Branding} from '../Branding.types'

import LogoCBR from './icons/Logo-CBR-BE.svg'

export const hubCBRBe: Branding = {
  nationalBrandName: 'CBR',
  nationalLogoUrl: LogoCBR,
  nationalUrl: 'https://www.cbr.be/',
  nationalLogoTitle: 'CBR',
  contactEmail: 'HConnect@cbr.be',
  contactEmailSubject: 'Notez la raison de votre demande / Noteer de reden van uw vraag',
  pageTitle: 'CBR',
  country: 'BE'
}
