import {Branding} from '../Branding.types'

import LogoMebin from './icons/Logo-Mebin-NL.svg'

export const hubMebinNl: Branding = {
  nationalBrandName: 'Mebin',
  nationalLogoUrl: LogoMebin,
  nationalUrl: 'https://www.mebin.nl/',
  nationalLogoTitle: 'Mebin',
  contactEmail: 'HConnect@mebin.nl',
  contactEmailSubject: 'Noteer de reden van uw vraag',
  pageTitle: 'Mebin',
  country: 'NL'
}
