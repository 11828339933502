import {get} from 'lodash'

import {empty} from './branding/empty'
import {hubCBRBe} from './branding/hub-cbr-be'
import {hubConcreteHk} from './branding/hub-concrete-hk'
import {hubENCINl} from './branding/hub-enci-nl'
import {hubGEMFR} from './branding/hub-gsmgranulats-fr'
import {hubHansonComAu} from './branding/hub-hanson-com-au'
import {hubHeidelbergCementCom} from './branding/hub-heidelbergcement-com'
import {hubHeidelbergCementGe} from './branding/hub-heidelbergcement-ge'
import {hubHeidelbergMaterialsBa} from './branding/hub-heidelbergmaterials-ba'
import {hubHeidelbergMaterialsBenelux} from './branding/hub-heidelbergmaterials-benelux-com'
import {hubHeidelbergMaterialsBg} from './branding/hub-heidelbergmaterials-bg'
import {hubHeidelbergMaterialsCoUk} from './branding/hub-heidelbergmaterials-co-uk'
import {hubHeidelbergMaterialsDe} from './branding/hub-heidelbergmaterials-de'
import {hubHeidelbergMaterialsDk} from './branding/hub-heidelbergmaterials-dk'
import {hubHeidelbergMaterialsFr} from './branding/hub-heidelbergmaterials-fr'
import {hubHeidelbergMaterialsIt} from './branding/hub-heidelbergmaterials-it'
import {hubHeidelbergMaterialsNo} from './branding/hub-heidelbergmaterials-no'
import {hubHeidelbergMaterialsPl} from './branding/hub-heidelbergmaterials-pl'
import {hubHeidelbergMaterialsRo} from './branding/hub-heidelbergmaterials-ro'
import {hubHeidelbergMaterialsSe} from './branding/hub-heidelbergmaterials-se'
import {hubHeidelbergMaterialsUs} from './branding/hub-heidelbergmaterials-us'
import {hubHymixComAu} from './branding/hub-hymix-com-au'
import {hubInterbetonBe} from './branding/hub-interbeton-be'
import {hubMebinNl} from './branding/hub-mebin-nl'
import {hubMqpCoUk} from './branding/hub-mqp-co-uk'
import {hubPnqComAu} from './branding/hub-pnq-com-au'
import {hubUnibetonFr} from './branding/hub-unibeton-fr'
import {localhost} from './branding/localhost'
import {ruHubHconnectDigital} from './branding/ru-hub-hconnect-digital'
import {Branding} from './Branding.types'

interface BrandingType {
  [id: string]: Branding
}

export const brandings: BrandingType = {
  'hub-heidelbergcement-com': hubHeidelbergCementCom,
  'hub-concrete-hk': hubConcreteHk,
  'hub-hanson-com-au': hubHansonComAu,
  'hub-hymix-com-au': hubHymixComAu,
  'hub-heidelbergcement-ge': hubHeidelbergCementGe,
  'hub-interbeton-be': hubInterbetonBe,
  'hub-mebin-nl': hubMebinNl,
  'hub-cbr-be': hubCBRBe,
  'hub-enci-nl': hubENCINl,
  'ru-hub-hconnect-digital': ruHubHconnectDigital,
  'hub-gsm-fr': hubGEMFR,
  'hub-unibeton-fr': hubUnibetonFr,
  'hub-heidelbergmaterials-us': hubHeidelbergMaterialsUs,
  'hub-heidelbergmaterials-dk': hubHeidelbergMaterialsDk,
  'hub-heidelbergmaterials-se': hubHeidelbergMaterialsSe,
  'hub-heidelbergmaterials-no': hubHeidelbergMaterialsNo,
  'hub-heidelbergmaterials-de': hubHeidelbergMaterialsDe,
  'hub-heidelbergmaterials-fr': hubHeidelbergMaterialsFr,
  'hub-pnq-com-au': hubPnqComAu,
  'hub-heidelbergmaterials-ro': hubHeidelbergMaterialsRo,
  'hub-heidelbergmaterials-co-uk': hubHeidelbergMaterialsCoUk,
  'hub-heidelbergmaterials-it': hubHeidelbergMaterialsIt,
  'hub-heidelbergmaterials-pl': hubHeidelbergMaterialsPl,
  'hub-heidelbergmaterials-benelux-com': hubHeidelbergMaterialsBenelux,
  'hub-mqp-co-uk': hubMqpCoUk,
  'hub-heidelbergmaterials-bg': hubHeidelbergMaterialsBg,
  'hub-heidelbergmaterials-ba': hubHeidelbergMaterialsBa,
  localhost
}

export const getBranding = (hostName: string) => brandings[hostName] || empty

export const stripHostName = (hostName: string): string =>
  hostName
    // remove stages
    .replace(/-(qa|dev|uatr|uat|dptest|regression)/, '')
    // create filename-friendly-name
    .replace(/\./g, '-')

export const getBrandingIdentifierFromUrl = (urlInstance: URL): string | null => {
  return stripHostName(urlInstance.hostname)
}

export const getBrandingFromUrl = (url: URL): Branding => {
  const brandIdentifier = getBrandingIdentifierFromUrl(url) || getBrandIdentifier()
  return get(brandings, brandIdentifier, empty)
}

export const getBrandIdentifier = (): string =>
  stripHostName(localStorage.getItem('branding_override') || window.location.hostname)

interface ContactHrefType {
  contactEmail?: string
  contactEmailSubject?: string
  contactFormHref?: string
}
export const getContactHref = ({
  contactEmail,
  contactEmailSubject,
  contactFormHref
}: ContactHrefType) => {
  if (contactFormHref) {
    return contactFormHref
  } else if (contactEmail) {
    return `mailto:${contactEmail}?subject=${encodeURIComponent(contactEmailSubject || '')}`
  }
  return ''
}
