import {Branding} from '../Branding.types'

import LogoUnibeton from './icons/Logo-Unibeton-FR.svg'

export const hubUnibetonFr: Branding = {
  nationalBrandName: 'Unibéton',
  nationalLogoUrl: LogoUnibeton,
  nationalUrl: 'hub.unibeton.fr',
  nationalLogoTitle: 'Unibéton',
  pageTitle: 'Unibéton',
  country: 'FR',
  contactEmail: 'hconnect@unibeton.fr',
  contactEmailSubject: 'Message du portail clients HUB Unibéton'
}
