import {Branding} from '../Branding.types'

import LogoHCGeorgia from './icons/Logo-Georgia-GE.svg'

export const hubHeidelbergCementGe: Branding = {
  nationalBrandName: 'HeidelbergCement Georgia',
  nationalLogoUrl: LogoHCGeorgia,
  nationalLogoTitle: 'HeidelbergCement Georgia',
  contactEmail: 'customerportal@heidelbergcement.ge',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'HeidelbergCement Georgia',
  country: 'GE'
}
