import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM.svg'

export const hubHeidelbergMaterialsIt: Branding = {
  nationalBrandName: 'Heidelberg Materials Italy',
  nationalLogoUrl: LogoHM,
  nationalLogoTitle: 'Heidelberg Materials Italy',
  nationalUrl: 'https://hub.heidelbergmaterials.it',
  contactEmail: 'direzionecommerciale.ita@heidelbergmaterials.com',
  contactEmailSubject: 'Mail de la Hub - HeidelbergMaterials ITA',
  pageTitle: 'Heidelberg Materials Italy',
  country: 'IT'
}
