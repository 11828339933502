import {Branding} from '../Branding.types'

import LogoInterbeton from './icons/Logo-Interbeton-BE.svg'

export const hubInterbetonBe: Branding = {
  nationalBrandName: 'Inter-Beton',
  nationalLogoUrl: LogoInterbeton,
  nationalUrl: 'https://www.interbeton.be/',
  nationalLogoTitle: 'Inter-Beton',
  contactEmail: 'HConnect@interbeton.be',
  contactEmailSubject: 'Notez la raison de votre demande / Noteer de reden van uw vraag',
  pageTitle: 'Inter-Beton',
  country: 'BE'
}
