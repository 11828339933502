import {Branding} from '../Branding.types'

import LogoHymix from './icons/Logo-Hymix-09-07-58-077.svg'

export const hubHymixComAu: Branding = {
  nationalBrandName: 'Hymix',
  nationalLogoUrl: LogoHymix,
  nationalLogoTitle: 'Hymix',
  nationalUrl: 'https://www.hymix.com.au',
  contactEmail: 'digital@hymix.com.au',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'Hymix',

  country: 'AU',
  termsAndConditionsOfSale:
    'https://hymix.com.au/wp-content/uploads/2023/06/Hymix-Terms-and-Conditions-of-Sale-VERSION-2.20-June-2023.pdf',
  paymentConfirmationMail: 'accounts@hymix.com.au',
  paymentGuideLink:
    'https://hymix.com.au/wp-content/uploads/2023/06/HG165HN_Hymix_Hub_Payment_Function_v2.pdf',
  digitalDocketsGuideLink: 'https://hymix.com.au/hymix_hub__docket_format_update_external/'
}
