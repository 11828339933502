import {Branding} from '../Branding.types'

import LogoGSM from './icons/Logo-GSM-FR.svg'

export const hubGEMFR: Branding = {
  nationalBrandName: 'GSM',
  nationalLogoUrl: LogoGSM,
  nationalUrl: 'hub.gsm-granulats.fr',
  nationalLogoTitle: 'GSM',
  pageTitle: 'GSM',
  country: 'FR',
  contactEmail: 'hconnect@gsm-granulats.fr',
  contactEmailSubject: 'Message du portail clients HUB GSM'
}
