import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM.svg'

export const hubHeidelbergMaterialsSe: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalLogoTitle: 'Heidelberg Materials',
  nationalUrl: 'https://www.heidelbergmaterials.se/',
  contactEmail: 'hub-se@heidelbergmaterials.com',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'Heidelberg Materials',
  country: 'SE'
}
