import {Branding} from '../Branding.types'

import LogoHCRussia from './icons/Logo-HC-Russia-09-07-58-064.svg'

export const ruHubHconnectDigital: Branding = {
  nationalBrandName: 'HeidelbergCement',
  nationalLogoUrl: LogoHCRussia,
  nationalLogoTitle: 'HeidelbergCement',

  contactEmail: 'rus.support@hconnect.digital',
  contactEmailSubject: 'Обратная связь HUB',
  contactFormHref:
    process.env.REACT_APP_STAGE === 'prod'
      ? 'https://forms.office.com/e/J1wdQYFkC0'
      : 'https://forms.office.com/e/7BbcMCMBzd',

  pageTitle: 'HeidelbergCement',

  country: 'RU'
}
