import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM.svg'

export const hubHeidelbergMaterialsPl: Branding = {
  nationalBrandName: 'Heidelberg Materials Polska',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'https://hub.heidelbergmaterials.pl/',
  nationalLogoTitle: 'Heidelberg Materials Polska',
  contactEmail: 'hub-polska@heidelbergmaterials.com',
  contactEmailSubject: 'HeidelbergMaterials',
  pageTitle: 'Heidelberg Materials Polska',
  country: 'PL',
  dispatchPhoneNumber: '+48 777779010'
}
