import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM.svg'

export const hubHeidelbergCementCom: Branding = {
  nationalBrandName: 'HeidelbergCement',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'https://www.heidelbergcement.com',
  nationalLogoTitle: 'HeidelbergCement',

  contactEmail: 'customerportal@lehighhanson.com',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'Hub',

  safetyDataSheetLink: 'https://www.heidelbergcement.de/de/sdb',
  dispatchPhoneNumber: '09471 70753500',

  country: 'US'
}
