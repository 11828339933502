import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM.svg'

export const hubHeidelbergMaterialsFr: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalLogoTitle: 'Heidelberg Materials',
  nationalUrl: 'https://hub.heidelbergmaterials.fr',
  contactEmail: 'hconnect-fr@heidelbergmaterials.com',
  contactEmailSubject: 'Courrier du Hub Portal',
  pageTitle: 'Heidelberg Materials',
  termsOfSales:
    'https://www.ciments-calcia.fr/sites/default/files/2023-01/CIMENTS%20CALCIA%20CGV%20janvier%202023-%20num%C3%A9ro%20SYDEREP%20version%20propre.pdf',
  safetyInstructionsAndGeneralTermsOfSaleRMC:
    'https://www.unibeton.fr/sites/default/files/2023-01/CGV%20UB%20Professionnels%202023%20%204%20pages-13.1.23.pdf',
  safetyInstructionsAndGeneralTermsOfSaleAGG:
    'https://www.gsm-granulats.fr/sites/default/files/2022-12/CGV%20mars%2021.pdf',
  country: 'FR'
}
