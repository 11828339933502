import {Branding} from '../Branding.types'

import LogoENCI from './icons/Logo-ENCI-NL.svg'

export const hubENCINl: Branding = {
  nationalBrandName: 'ENCI',
  nationalLogoUrl: LogoENCI,
  nationalUrl: 'https://www.enci.nl/',
  nationalLogoTitle: 'ENCI',
  contactEmail: 'HConnect@enci.nl',
  contactEmailSubject: 'Noteer de reden van uw vraag',
  pageTitle: 'ENCI',
  country: 'NL'
}
