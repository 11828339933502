import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM.svg'

export const hubHeidelbergMaterialsUs: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalLogoTitle: 'Heidelberg Materials',
  nationalUrl: 'https://www.heidelbergmaterials.us/',
  contactEmail: 'nam.customerportal@heidelbergmaterials.com',
  contactEmailSubject: 'Mail from Hub Portal',
  hconnectLinkUrl: '//hub.hconnect.digital/nam/',
  safetyDataSheetLink: 'https://www.heidelbergmaterials.us/resources/safety-data-sheets',

  pageTitle: 'Heidelberg Materials',

  country: 'US',

  termsAndConditionsOfSale: 'https://www.heidelbergmaterials.us/quote-terms',

  paymentConfirmationMail: 'NAMAR.USAShared@heidelbergmaterials.us'
}
