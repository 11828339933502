import {Branding} from '../Branding.types'

import LogoHanson from './icons/Logo-Hanson-AU.svg'

export const hubHansonComAu: Branding = {
  nationalBrandName: 'Hanson',
  nationalLogoUrl: LogoHanson,
  nationalLogoTitle: 'Hanson',
  nationalUrl: 'https://www.hanson.com.au',
  contactEmail: 'aus-support@hconnect.digital',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'Hanson',

  country: 'AU',
  termsAndConditionsOfSale:
    'https://www.hanson.com.au/media/6139/hanson-terms-and-conditions-of-sale.pdf',
  paymentConfirmationMail: 'wa.accounts@hanson.com.au',
  paymentGuideLink: 'https://www.hanson.com.au/media/8252/hg163hn_hub_payment_function_v4.pdf',
  digitalDocketsGuideLink: 'https://www.hanson.com.au/hanson_external_docket_format_update.pdf'
}
