import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM.svg'

export const localhost: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'https://www.heidelbergmaterials.com',
  nationalLogoTitle: 'Heidelberg Materials',

  contactEmail: 'hub.zement@heidelbergmaterials.com',
  contactEmailSubject: 'Mail from Hub Portal',
  pageTitle: 'Heidelberg Materials',

  safetyDataSheetLink: 'https://www.heidelbergcement.de/de/sdb',

  country: 'DE'
}
