import LogoHK from './icons/Logo-HK.svg'

import {Branding} from '../Branding.types'

export const hubConcreteHk: Branding = {
  nationalBrandName: 'Alliance Construction Materials Ltd.',
  nationalLogoUrl: LogoHK,
  nationalLogoTitle: 'Alliance',
  nationalUrl: 'https://www.concrete.hk',
  contactEmail: 'support@hconnect.digital',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'Alliance',

  country: 'HK'
}
