import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM.svg'

export const empty: Branding = {
  nationalBrandName: 'nationalBrandName',
  nationalLogoUrl: LogoHM,
  nationalUrl: 'https://www.heidelbergmaterials.com',
  nationalLogoTitle: 'nationalLogoTitle',

  contactEmail: 'hchubdev@gmail.com',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'pageTitle',

  safetyDataSheetLink: 'https://www.heidelbergcement.de/de/sdb',

  country: 'US',

  dispatchPhoneNumber: '+48 777779010'
}
